.about-container {
  margin: 4rem 6rem;
  text-align: start;
  justify-content: center;
  align-items: center;
}

.about-container h1 {
  padding-bottom: 1rem;
  text-align: center;
  color: darkmagenta;
}

.about-container h2 {
  padding-bottom: 1rem;
  text-align: center;
  top: 100px;
  color: darkmagenta;
}

.about-container h4 {
  padding-bottom: 1rem;
  text-align: center;
  color: #000080;
}

.about-container p {
  padding-bottom: 2rem;
  color: darkblue;
}

.about-container img {
  position: relative;
  margin: 0rem 35%;
  height: 320px;
  width: 390px;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
}

.about-photo img {
  width: 500px;
  height: 100%;
  border-radius: 9px;
  box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin: 2rem;
  transition: 400ms;
}

.about-photo img:hover {
  transform: scale(1.03);
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 2rem;
  }
  .about-container img {
   margin: auto;
    height: 250px;
    width: 300px;
  }
  .about-photo img {
    position: relative;
    height: 280px;
    width: 200px;
  }

  .about-container h1 {
    padding-bottom: 1rem;
    text-align: center;
    color: darkmagenta;
    font-size: 1.1rem;
  }

  .about-container h2 {
    padding-bottom: 0.1rem;
    text-align: center;
    top: 100px;
    color: darkmagenta;
    font-size: 0.9rem;
  }
  .about-container h4 {
    padding-bottom: 1rem;
    text-align: center;
    color: #000080;
    font-size: 0.7rem;
  }
  .about-container p {
    padding-bottom: 2rem;
    color: darkblue;
    font-size: 0.7rem;
  
  }
  
}
