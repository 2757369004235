.Awards-Photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .Awards-Photo img {
    width: 400px;
    height: auto; /* Maintain aspect ratio */
    border-radius: 9px;
    box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
    cursor: pointer;
    margin: 2rem;
    transition: 400ms;
  }
  
  .Awards-Photo img:hover {
    transform: scale(1.03);
  }
  
  .awards-info {
    text-align: center;
    width: 100%; /* Ensure it takes the full width */
    margin-top: 2rem;
  }
  
  .awards-text h1 {
    font-size: 2rem;
    color: #a3248f;
    text-align: center;
  }
  
  .awards-text {
    margin: 4rem 6rem;
    white-space: pre-line;
  }
  
  .register-button {
    display: inline-block;
    padding: 1rem 2rem;
    margin: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    background-color: #a3248f;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #800080;
  }
  
  @media screen and (max-width: 850px) {
    .Awards-Photo {
      flex-direction: column;
      padding: 1rem;
    }
  
    .Awards-Photo img {
      width: 80%;
      height: auto;
      margin: 1rem 0;
    }
  
    .awards-info {
      margin-top: 1rem;
    }
  
    .awards-text {
      font-size: 1rem;
      margin: 1rem;
    }
  
    .register-button {
      width: 50%;
      font-size: 1rem;
      text-align: center;
    }
  }
  