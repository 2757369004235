.referral {
    color: #2a2a2a;
    margin: 4rem 6rem;
}

.referral h1 {
    font-size: 2rem;
    color: #a3248f;
    text-align: center;
    margin-bottom: 0; /* Ensure no margin at the bottom */
}

.referral p {
    color: #a3248f;
    text-align: center;
    margin-top: 0.5rem;
}

.referralcard {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusts card size and wraps */
    gap: 1rem; /* Space between cards */
    justify-items: center; /* Center cards horizontally */
    margin-bottom: 0;
}

.r-card {
    width: 60%; /* Adjust card width */
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    cursor: pointer;
    padding: 0.5rem 0.4rem; /* Reduce padding */
}

.r-image {
    height: 250px; /* Reduce image height */
    width: auto;
    overflow: hidden;
    border-radius: 7px;
}

.r-image img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.r-card h4 {
    font-size: 1.5rem; /* Reduce font size */
    padding: 0.5rem 0 0.1rem 0; /* Adjust padding */
    text-align: center;
    margin-bottom: 0; /* Ensure no margin at the bottom */
    margin-top: 0.5rem;
}

.r-card p {
    font-size: 0.9rem; /* Reduce font size */
    margin-top: 0.5rem; /* Reduced top margin */
    margin-bottom: 0.5rem;
}

.r-card a {
    font-size: 1rem;
    margin-top: 0.5rem; /* Reduced top margin */
    text-align: center;
    color: #a3248f;
    text-decoration: none;
    display: inline-block;
    padding: 0.5rem 1rem; /* Add padding for better appearance */
    font-weight: bold; /* Makes text bold */
    border-radius: 5px; /* Rounded corners */
    background-color: #f1ee23; /* Background color for contrast */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.referral a:hover {
    color: rgb(255, 0, 0);
}

@media screen and (max-width: 850px) {
    .referral {
        margin: 4rem 2rem;
    }

    .referralcard {
        flex-direction: column;
    }

    .referral h1 {
        font-size: 1.5rem; /* Further reduce font size */
        color: #a3248f;
        text-align: center;
        margin-bottom: 0; /* Ensure no margin at the bottom */
    }

    .r-card {
        width: 92%; /* Make cards full-width on small screens */
        margin-bottom: 1.5rem;
    }

    .r-image {
        height: 200px; /* Reduce image height for small screens */
        width: auto;
        overflow: hidden;
        border-radius: 7px;
    }

    .r-card h4 {
        font-size: 1.2rem; /* Further reduce font size */
        padding: 0.5rem 0 0.1rem 0; /* Adjust padding */
        text-align: center;
        margin-bottom: 0.5rem; /* Ensure no margin at the bottom */
        margin-top: 0.5rem;
    }
}
