.from-container {
  margin: 4rem 6rem;
  color: #a3248f;
}

.from-container form {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
}

.from-container input {
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #a3248f;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

.popup button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #a3248f;
  color: white;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
}

.popup button:hover {
  background-color: #7b185e;
}



.from-container textarea {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #a3248f;
}

.from-container button {
  display: inline;
}

@media screen and (max-width: 850px) {
  .from-container {
    margin: 4rem 2rem;
  }

  .from-container form {
    padding-top: 2rem;
    width: 90%;
  }
}
