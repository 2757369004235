.Information {
  margin: 4rem 6rem;
  white-space: pre-line;
}

.Information h1 {
  font-size: 2rem;
  color: #a3248f;
  text-align: center;
 
}

.Information p {
  color: #a3248f;
 
}

.des-text .text {
  white-space: pre-line;
}

.centered-paragraph {
  text-align: center;
  color: #a3248f; /* Optional: to match the color */
  font-size: 1.2rem; /* Adjust font size if needed */
  margin: 1rem 0; /* Add vertical spacing if needed */
}


.first-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
  white-space: pre-line;
  
}

.first-des-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
  white-space: pre-line;
}

.des-text {
  width: 45%;
  text-align: start;
  font-size: 1rem;
  color: #a3248f;
  white-space: pre-line;
}

.des-text h2 {
  padding-bottom: 1rem;
  color: #ff4d01;
}

.image {
  position: relative;
  width: 30%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.image img {
  width: 49%;
  height: 400px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
}

.image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

@media screen and (max-width: 850px) {
  .Information {
    margin: 4rem 2rem;
  }
  .first-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
    
  }

  .first-des-reverse {
    display: flex;
    flex-direction: column-reverse;

    margin-top: 4.5rem;
  }

  .des-text {
    width: 100%;
    white-space: pre-line;
  }

  .image {
    width: 100%;
    margin: 1.8rem 0;
  }

  .image img {
    height: 320px;
  }
  .centered-paragraph {
    text-align: center;
    color: #a3248f; /* Optional: to match the color */
    font-size: 0.6rem; /* Adjust font size if needed */
    margin: 1rem 0; /* Add vertical spacing if needed */
  }

  .Information h1 {
    font-size: 1.3rem;
    color: #a3248f;
    text-align: center;
   
  }

}
