.Gallery-Photo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
 

.Gallery-Photo h1 {
 
  text-align: center;
  color: #ff4d01;
  top: 900px;
}

.Gallery-Photo img {
  width: 400px;
  height: 230px;
  border-radius: 9px;
  box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin: 10px;
  transition: 400ms;
}

.Gallery-Photo video {
  width:400px;
          height:230px;
  border-radius: 9px;
  box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin: 10px;
  transition: 400ms;
}

.Gallery-Photo img:hover {
  transform: scale(1.03);
}

@media screen and (max-width: 850px) {
  .Gallery-Photo {
    display: flex;
  }
  .Gallery-Photo img {
    width: 350px;
    height: 230px;}

    .Gallery-Photo video {
      width: 350px;
      height: 230px;}

  .Gallery-Photo img:hover {
    transform: scale(1.03);
  }
}
