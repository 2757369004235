.ImportanceUss-Photo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ImportanceUss-Photo img{
  width: 500px;
    height: 400px;
    border-radius: 9px;
    box-shadow: -1px 1px 82px -18px rgba(0, 0, 0, 0.19);
    cursor: pointer;
    margin: 10px;
    transition: 400ms;
}
.ImportanceUss-Photo img:hover{
  transform: scale(1.03);
}


  @media screen and (max-width: 850px) {
    .ImportanceUss-Photo {
      display: flex;
    }
    .ImportanceUss-Photo img {
      width: 400px;
      height: 300px;;
    }
    .ImportanceUss-Photo img:hover {
      transform: scale(1.03);
    }
  }
  