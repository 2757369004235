.hero {
  width: 100%;
  height: 130vh;
  position: relative;
}

.hero-mid {
  width: 100%;
  height: 80vh;
  position: relative;
}

.hero-mid01 {
  width: 100%;
  height: 100vh;
  position: relative;
}

.hero-mid h1,
.hero-mid h2 {
  white-space: pre-line;
}

.hero-mids {
  width: 100%;
  height: 150vh;
  position: relative;
}

img {
  width: 100%;
  height: 100%;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-mid01 .hero-text h1 {
  font-size: 3rem;
  font-weight: 800;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
  margin-top: 0; /* Added */
}

.hero-text h1 {
  font-size: 3rem;
  font-weight: 800;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0; /* Adjusted */
}

.hero-text h2 {
  font-size: 2rem;
  font-weight: 800;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0; /* Adjusted */
}

.hero-mid .hero-text img,
.hero-mid01 .hero-text img {
  width: 400px;
  height: 230px;
  cursor: pointer;
  margin: 10px;
  transition: 400ms;
  margin-top: 100px;
}

.hero-mids1 {
  width: 100%;
  height: 85vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-mids1 .hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-mids1 .hero-text h1,
.hero-mids1 .hero-text h2 {
  font-size: 3rem;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 0;
}

.hero-mids1 .hero-text h2 {
  font-size: 2rem;
  margin-top: 0;
}

.hero-mids1 .hero-text img {
  width: 300px;
  height: 300px;
  border: 2px solid #ffef14;
  border-radius: 5px;
  margin-top: 90px;
  margin-bottom: 2rem;
}

.hero-mids1 .hero-text p {
  font-size: 1.2rem;
  color: #fffb00;
  padding: 0 2rem;
  margin: 10px 0 0 0;
}

.hero-text img {
  width: 400px;
  height: 230px;
  cursor: pointer;
  margin: 10px;
  transition: 400ms;
}

.hero-text p {
  font-size: 1.2rem;
  color: #fff;
  padding: 0 2rem;
}

.hero-mids .hero-text .show,
.hero-text .show {
  text-decoration: none;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #a3248f;
  margin-top: auto;
}

.hero-mids .hero-text .show2,
.hero-text .show2,
.hero-mid01 .hero-text .show2 {
  text-decoration: none;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 9px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #a3248f;
  margin-bottom: auto;
  margin-bottom: 10px;
}

@media screen and (max-width: 850px) {
  .hero {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .hero-mids {
    width: 100%;
    height: 130vh;
    position: relative;
  }

  .hero-mid {
    width: 100%;
    height: 60vh;
    position: relative;
  }

  .hero-mids1 {
    width: 100%;
    height: 65vh;
    position: relative;
  }

  .hero-mid01 {
    width: 100%;
    height: 75vh;
    position: relative;
  }

  .hero-mids1 .hero-text h1,
  .hero-mids1 .hero-text h2 {
    font-size: 2rem;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding: 0;
  }

  .hero-mid01 .hero-text img {
    margin-top: 50px;
    width: 300px;
    height: 200px;
  }

  .hero-mid .hero-text img {
    margin-top: 150px;
    width: 300px;
    height: 200px;
  }

  .hero-mids1 .hero-text img {
    margin-top: 100px;
    width: 200px;
    height: 250px;
    border: 2px solid #e3fa10;
  }

  .hero-text h1 {
    font-size: 1.5rem;
    font-weight: 800;
    padding: 5px 10px;
    white-space: pre-line;
  }

  .hero-text h2 {
    font-size: 1.1rem;
    font-weight: 800;
    white-space: pre-line;
  }

  .hero-text {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    white-space: pre-line;
  }

  .hero-text p {
    font-size: 0.9rem;
    padding: 0.2rem 0 1rem 0;
  }

  .hero-text .show,
  .hero-text .show2 {
    padding: 0.6rem 1.1rem;
    border-radius: 6px;
    font-size: 1rem;
    background: yellow;
    color: #a3248f;
  }

  .hero-text img {
    width: 300px;
    height: 200px;
  }
}
