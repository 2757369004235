.FranchiseRef {
    color: #2a2a2a;
    margin: 4rem 6rem;
}

.FranchiseRef h1 {
    font-size: 3rem;
    color: #a3248f;
    text-align: center;
    margin-bottom: 0; /* Ensure no margin at the bottom */
}

.FranchiseRef p {
    color: #a3248f;
    text-align: center;
    margin-top: 0.5rem;
}

.FranchiseRefcard {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusts card size and wraps */
    gap: 1rem; /* Space between cards */
    justify-items: center; /* Center cards horizontally */
    margin-bottom: 0;
}

.fr-card {
    width: 70%; /* Make cards smaller */
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    cursor: pointer;
    padding: 0.5rem 0.4rem; /* Reduced padding */
}

.fr-image {
    height: 250px; /* Reduced height for smaller cards */
    width: auto;
    overflow: hidden;
    border-radius: 7px;
}

.fr-image img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.fr-card h4 {
    font-size: 1.6rem; /* Adjusted font size for smaller cards */
    padding: 0.5rem 0 0.1rem 0; /* Reduced padding */
    text-align: center;
    margin-bottom: 0; /* Ensure no margin at the bottom */
    margin-top: 0.5rem;
}

.fr-card p {
    font-size: 0.9rem; /* Adjusted font size */
    margin-top: 0.5rem; /* Reduced top margin */
    margin-bottom: 0.5rem;
}

.fr-card a {
    font-size: 0.9rem; /* Adjusted font size */
    margin-top: 0.5rem; /* Reduced top margin */
    text-align: center;
    color: #13c91c;
    text-decoration: none;
}

.FranchiseRef a:hover {
    color: rgb(255, 0, 0);
}
@media screen and (max-width: 850px) {
    .FranchiseRef {
        margin: 4rem 2rem;
    }

    .FranchiseRefcard {
        flex-direction: column;
    }
    .FranchiseRef h1 {
        font-size: 1.9rem;
        color: #a3248f;
        text-align: center;
        margin-bottom: 0; /* Ensure no margin at the bottom */
    }

    .fr-card {
        width: 80%;
        margin-bottom: 1.5rem;
    }
    .fr-image {
        height: 300px;
        width: auto;
        overflow: hidden;
        border-radius: 7px;
    }
    .fr-card h4 {
        font-size: 1.3rem;
        padding: 0.9rem 0 0.1rem 0; /* Reduced bottom padding */
        text-align: center;
        margin-bottom: 0.5rem; /* Ensure no margin at the bottom */
        margin-top: 0.5rem;
    }
}
