.Topper {
  color: #2a2a2a;
  margin: 4rem 6rem;
}

.Topper h1 {
  font-size: 3rem;
  color: #a3248f;
  text-align: center;
}

.Topper p {
  color: #a3248f;
  text-align: center;
}

.Toppercard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.t-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
}


.t-image {
  height: 600px;
width: auto;
  overflow: hidden;
  border-radius: 7px;
}

.t-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.t-image:hover img {
  transform: scale(1.3);
}

.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .Topper {
    margin: 4rem 2rem;
  }

  .Toppercard {
    flex-direction: column;
  }

  .t-card {
    width: 92%;
    margin-bottom: 1.5rem;
  }
  .t-image {
    height: 400px;
  width: auto;
    overflow: hidden;
    border-radius: 7px;
  }
  
}
