/* Popup overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  /* Popup content */
  .popup-content {
    position: relative;
    background: #fff;
    padding: 5px;
    max-width: 60%;
    max-height: 60%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1; /* Ensures square shape */
  }
  
  /* Image inside the popup */
  .popup-content img {
    width: 1000%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    font-size: 24px;
    color: #333;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  /* Hover effect */
  .close-button:hover {
    color: red;
  }
  
  /* Media query for screens up to 850px */
  @media screen and (max-width: 850px) {
    .popup-content {
      padding: 5px;
      max-width: 85%;
      max-height: 85%;
      aspect-ratio: 1 / 1;
    }
    .close-button {
        top: 10px;
        right: 10px;
        font-size: 20px;
        padding: 5px;
      }
  }
  
  